import React from "react"
import Seo from "../components/Seo"

const ServerErrorPage = () => {
  return (
    <>
      <Seo title="Błąd 500" />
      <div className="c-Error">
        Wystąpił błąd komunikacji z serwerem, prosimy spróbowac odświeżyć
        stronę.
      </div>
    </>
  )
}

export default ServerErrorPage
